import React, { useState, useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, Checkbox, Card, Collapse, Alert, message } from 'antd';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';
import DatePicker from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import PresetValuesPlugin from '../components/datePickerPlugins/PresetValuesPlugin';
import Icon from "react-multi-date-picker/components/icon";
import { CarryForwardCheck } from 'components/CarryForwardCheck';
import * as XLSX from 'xlsx';

const { Option } = Select;

export function AggregationQuery({ auto_search = false }) {
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [yearsFormat, setYearsFormat] = useState("Ascending")
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const indicatorsState = useSelector((state) => state.indicators);
    const unitsState = useSelector((state) => state.units);
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [countryMap, setCountryMap] = useState({});
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [aggregationIndicators, setAggregationIndicators] = useState([]);
    const [mopDataWarn, setMopDataWarn] = useState(false);
    const [yearsRange, setYearsRange] = useState([]);
    const [carryForwardCheck, setCarryForwardCheck] = useState(false);
    const [carryForwardBaseYear, setCarryForwardBaseYear] = useState('')   

    const renderTagPlaceholder = (omittedValues) => {
        // Sort the omitted values alphabetically based on their labels
        const sortedOmittedValues = [...omittedValues].sort((a, b) => parseInt(a.value) - parseInt(b.value));

        // Create a multiline string of all sorted omitted values
        const fullList = sortedOmittedValues.map(val => val.label).join('\n');
        return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{fullList}</span>}>
                <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
        );
    };

    useEffect(() => {
        if (indicatorsState.status === 'succeeded') {
            AxiosOICStat.get('/indicators/descriptions').then(response => {
                let temp = [];
                response.data.forEach((ind) => {
                temp.push(ind.ind_code + " - " + ind.ind_name_eng);
                });
                setAggregationIndicators(temp);
            }
            );
        }
    }, [indicatorsState.status, indicatorsState.value]);


    useEffect(() => {
        // Assume these are the endpoints to fetch platform and mission data
        if (!auto_search) {

        }
        else {
            handleSearch();
        }
    }, [auto_search]);

    const handleCalculate = () => {
        message.warning("The calculation may take some time. Please wait...");
        setLoading(true);
        setMopDataWarn(false);
        const formattedYears = [];

        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });

        setYearsRange(formattedYears);
        
        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);
        if(carryForwardCheck){
            setMopDataWarn(true)
            AxiosOICStat.post('/aggregations/calculate-carry-forward', {
                regions: selectedRegions,
                indicators: formattedIndicators,
                years: formattedYears,
                base_year: carryForwardBaseYear
            }).then(response => {
                const updatedData = response.data.map((element, index) => {
                    element.key = index;
                    element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                    return element;
                });
                updatedData.forEach(element => {
                    element.r_name = regionMappingsData.find(region => region.id === element.r_code)?.region_name;
                });
                //customSetSearchResults(updatedData);
                setLoading(false);
                
                const transformedData = [];
    
                updatedData.forEach((item) => {
                    //console.log("ITEM:", item)
                    let existingEntry = transformedData.find(
                        (entry) => entry.ind_code === item.ind_code && entry.r_code === item.r_code
                    );
                    
                    const valuesArray = Object.values(indicatorsState.value); //Converting Array
                    const ind = valuesArray.find((entry) => entry.ind_code === item.ind_code);
                    const ind_name = ind.ind_name_eng
                    if (!existingEntry) {
                        existingEntry = {
                            key: transformedData.length,
                            r_code: item.r_code,
                            ind_name: ind_name,
                            ind_code: item.ind_code,
                            r_name: item.r_name,
                            u_name: item.u_name,
                            u_code: item.u_code,
                            agg_type: item.agg_type,
                            formatted_d_value: item.d_value,
                            d_date: item.d_date,
                            c_code: null,
                            c_name: null,
                            cat_name: null
                        };
                        transformedData.push(existingEntry);
                    }
        
                    existingEntry[item.d_year] = item.d_value;
                });
                //setSearchResults(updatedData);
                setSearchResults(transformedData);
            })
        }
        else{
            AxiosOICStat.post('/aggregations/calculate', {
                regions: selectedRegions,
                indicators: formattedIndicators,
                years: formattedYears
            }).then(response => {
                const updatedData = response.data.map((element, index) => {
                    element.key = index;
                    element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                    return element;
                });
                updatedData.forEach(element => {
                    element.r_name = regionMappingsData.find(region => region.id === element.r_code)?.region_name;
                });
                console.log("Updated Data", updatedData);
                //customSetSearchResults(updatedData);
                setLoading(false);
                
                const transformedData = [];
    
                updatedData.forEach((item) => {
                    //console.log("ITEM:", item)
                    let existingEntry = transformedData.find(
                        (entry) => entry.ind_code === item.ind_code && entry.r_code === item.r_code
                    );
                    
                    const valuesArray = Object.values(indicatorsState.value); //Converting Array
                    const ind = valuesArray.find((entry) => entry.ind_code === item.ind_code);
                    const ind_name = ind.ind_name_eng
                    if (!existingEntry) {
                        existingEntry = {
                            key: transformedData.length,
                            r_code: item.r_code,
                            ind_name: ind_name,
                            ind_code: item.ind_code,
                            r_name: item.r_name,
                            u_name: item.u_name,
                            u_code: item.u_code,
                            agg_type: item.agg_type,
                            formatted_d_value: item.d_value,
                            d_date: item.d_date,
                            c_code: null,
                            c_name: null,
                            cat_name: null
                        };
                        transformedData.push(existingEntry);
                    }
        
                    existingEntry[item.d_year] = item.d_value;
                });
    
                console.log(transformedData);
                console.log(updatedData);
                //setSearchResults(updatedData);
                setSearchResults(transformedData);
    
            });
        }       
    }

    const handleSearch = () => {
        setLoading(true);
        setMopDataWarn(false);
        const formattedYears = [];

        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });

        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        AxiosOICStat.post('/aggregations/warning', {
            regions: selectedRegions,
            indicators: formattedIndicators,
            years: formattedYears
        }).then(response => {
            if(response.data){
                message.warning("There is new calculation. Please wait...");
            }
        });

        AxiosOICStat.post('/aggregations/query', {
            regions: selectedRegions,
            indicators: formattedIndicators,
            years: formattedYears
        }).then(response => {
            const updatedData = response.data.map((element, index) => {
                element.key = index;
                element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                return element;
            });
            updatedData.forEach(element => {
                element.r_name = regionMappingsData.find(region => region.id === element.r_code)?.region_name;
            });
            //console.log("Updated Data", updatedData);
            setSearchResults(updatedData);
            setLoading(false);
        });

    };

    const handleCarryForward = (checked) => {
        setCarryForwardCheck(checked);
    };

    const handleYearChange = (year) => {
        setCarryForwardBaseYear(year);
    }

    //const customSetSearchResults = (updatedData) => {
    //    for (let index = 0; index < updatedData.length; index++) {
    //        const item = updatedData[index];
    //        if (item.d_value == 0 || item.d_value == null){
    //            if(index > 0){
    //                const prevItem = updatedData[index-1]
    //                if(prevItem.d_value != 0 && prevItem.d_value != null){
    //                    item.d_value = prevItem.d_value;
    //                    item.agg_type = item.agg_type+"*"
    //                    setMopDataWarn(true);
    //                }
    //            }
    //        }
    //    }
    //    console.log(updatedData)
    //    setSearchResults(updatedData);
    //}

    const modifyCarryForwardValues = (data) => {
        let lastValue = null;
    
        // Loop through the data and modify values if they're the same as the previous year
        const modifiedData = data.map((entry) => {
            const newEntry = { ...entry };
            var tempKeyArray = Object.keys(newEntry)
            console.log(tempKeyArray)

            for (let index = 1; index < tempKeyArray.length; index++) {
                const yearKey = tempKeyArray[index];
                const prevYearKey = tempKeyArray[index - 1];

                //return
                if(yearKey == "key"){break;}
                else{
                    if(newEntry[yearKey] == newEntry[prevYearKey]){
                        let value = newEntry[yearKey]
                        newEntry[yearKey] = `CF: ${value}`
                    }
                }

            }

            return newEntry
        });
        //console.log(modifiedData)
        return modifiedData;
    };

    const exportCarryForwardExcel = () => {
        // Get the modified data
        var modifiedData = modifyCarryForwardValues(searchResults);

        const fileName = "Carry-Forward Results";

        // Keys to drop
        const keysToDrop = ['c_code', 'cat_name', 'formatted_d_value', 'd_date', 'u_code', 'r_code', 'ind_code', 'c_name', 'key'];

        // Rename columns
        const renameColumns = {
            "agg_type": "Aggregation Type",
            "ind_name": "Indicator Name",
            "r_name": "Region Name",
            "u_name": "Unit Name"
        };

        // Extract year keys dynamically
        const yearKeys = Object.keys(modifiedData[0]).filter(key => /^\d{4}$/.test(key)); // Regex to match year format (e.g., '2019', '2020')

        // Define the desired order of columns dynamically
        const desiredOrder = [
            "Region Name",
            "Indicator Name",
            "Unit Name",
            "Aggregation Type",
            ...yearKeys // Add the dynamic year keys
        ];
        let filteredData;

        if (Array.isArray(modifiedData)) {
            filteredData = modifiedData.map(item => 
                Object.keys(item)
                    .filter(key => !keysToDrop.includes(key))
                    .reduce((obj, key) => {
                        obj[renameColumns[key] || key] = item[key];
                        return obj;
                    }, {})
            );
        } else {
            filteredData = Object.keys(modifiedData)
                .filter(key => !keysToDrop.includes(key))
                .reduce((obj, key) => {
                    obj[renameColumns[key] || key] = modifiedData[key]; 
                    return obj;
                }, {});

            filteredData = [filteredData];
        }

        const containsNumber = (str) => {
            return /\d/.test(str);
        };

        filteredData = filteredData.map(item => {
            const reorderedItem = {};
            desiredOrder.forEach(key => {
                if (containsNumber(key)){
                    reorderedItem[`Year: ${key}`] = item[key] !== undefined ? item[key] : null;
                }
                else{
                    reorderedItem[key] = item[key] !== undefined ? item[key] : null;
                }
                console.log(reorderedItem)
            });
            
            return reorderedItem;
        });

        const worksheet = XLSX.utils.json_to_sheet(filteredData);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };
    

    const exportToExcel = () => {
        if(carryForwardCheck){
            exportCarryForwardExcel()
            return;
        }
        const formattedYears = [];

        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });


        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        AxiosOICStat.post('/aggregations/query-csv', {
            regions: selectedRegions,
            indicators: formattedIndicators,
            years: formattedYears
        }).then(response => {
            console.log("response", response.data);
            response.data.forEach(item => console.log(item));
            // Dynamically construct headers based on the fixed fields and the years array
            const headers = [
                "Region Name", "Indicator", "Unit", "Year", "Last Update Date", "Aggregation Type", "Value"
            ];

            // Map response data to the format suitable for Excel export
            const dataForExport = response.data.map(item => {
                // Start with fixed fields
                const row = {
                    "Region Name": regionMappingsData.find(region => region.id === item.r_code)?.region_name,
                    "Indicator": indicatorsState.value[item.ind_code]?.ind_name_eng,
                    "Unit": unitsState.value[item.u_code]?.unit_name_eng,
                    "Year": item.d_year,
                    "Last Update Date": item.d_date,
                    "Aggregation Type": item.agg_type,
                    "Value": item.d_value
                };
                // Add dynamic year data
                /*item.years.forEach((value, index) => {
                    // Assuming formattedYears and item.years are aligned by index
                    const year = formattedYears[index];
                    row[year] = value !== null ? `"${value}"` : "\" \""; // Replace null with space for Excel
                });*/

                return row;
            });

            // Convert dataForExport to CSV string and include UTF-8 BOM
            const BOM = '\uFEFF';
            const csvContent = BOM + [
                headers.join(','), // Add the headers row
                ...dataForExport.map(row => headers.map(header => row[header]).join(',')) // Map each row to CSV format
            ].join('\n');

            // Trigger download of the CSV file
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'oicstat_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    useEffect(() => {
        // sort the selected values
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedYears(selectedYears.sort((a, b) => parseInt(a) - parseInt(b)));
    },
        [selectedIndicators, selectedYears]
    );

    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };

        fetchRegionMappings();
    }, []);

    const regions = [
        {
            id: 1,
            label: 'Regions',
            children: <Row gutter={[8, 8]}>
                {regionMappingsData && regionMappingsData.map((region) => (
                    <Col span={6} key={region.id}>
                        <Checkbox
                            key={region.id}
                            checked={selectedRegions.includes(region.id)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedRegions([...selectedRegions, region.id]);
                                } else {
                                    setSelectedRegions(selectedRegions.filter((id) => id !== region.id));
                                }
                            }}
                            value={region.id}
                        >
                            {region.region_name}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        }
    ]

    return (
        <>
            {!auto_search &&
                <Col style={{ marginBottom: '10px' }}>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "100%", backgroundColor: "white" }} />
                    <Row style={{marginBottom: '10px'}}>
                        <CarryForwardCheck carryForwardCheck={carryForwardCheck} onYearChange={handleYearChange} onSwitchChange={handleCarryForward}></CarryForwardCheck>
                    </Row>
                    <Row>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            //defaultValue={preSelectedIndicators} 
                            onChange={setSelectedIndicators}
                            style={{ minWidth: '500px', marginBottom: '5px', marginRight: '10px', maxWidth: '1000px' }}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            showSearch
                        >
                            {
                                aggregationIndicators.map((indicator) => (
                                    <Option key={indicator.split(" - ")[0]} value={indicator}>{indicator}</Option>
                                ))
                            }
                        </Select>

                        <Button shape="circle" value="large" type="primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DatePicker
                                onlyYearPicker
                                multiple
                                range
                                sort
                                highlightToday={false}
                                placeholderText={"Please select a date"}
                                selected={selectedYears}
                                value={selectedYears}
                                onChange={setSelectedYears}
                                maxDate={new Date()}
                                minDate={new Date(1970, 0, 1)}
                                plugins={[
                                    <DatePanel />,
                                    <PresetValuesPlugin position="left" setValue={setSelectedYears} />,
                                ]}
                                render={<Icon style={{ color: 'white', width: '85%', marginTop: '2px' }} />}
                            />
                        </Button>

                        <Button type="primary" disabled={true} onClick={handleSearch} style={{ marginLeft: '10px', }}>Search</Button>
                        <Button type="primary" onClick={handleCalculate} style={{ marginLeft: '10px', }}>Calculate</Button>
                    </Row>
                </Col>
            }
            <Row>
                <Col xs={24}>
                 <Select
                 placeholder="Years Order (Default:Ascending)"
                 onChange={setYearsFormat}
                style={{ minWidth: '500px', marginBottom: '10px', marginRight: '10px', maxWidth: '1000px' }}
                 >
                    <Option key={"ascending"} value={"Ascending"}>Ascending</Option>
                    <Option key={"descending"} value={"Descending"}>Descending</Option>
                 </Select>   
                </Col>
            </Row>
            <Row>
                {mopDataWarn && <Alert style={{ marginBottom: "10px" }} type="warning" message={<span><strong>Warning:</strong> There is carry-forward data.</span>} />}
                <Col xs={24}>
                    <DataTable className={"custom-table"} data={searchResults} columns={["r_name", "ind_name", "d_date", "u_name", "agg_type"]} widths={['15%', '55%', '10%', '10%']} loading={loading}
                            colYearView={true} yearRange={yearsRange} yearOrder={yearsFormat} showIndexColumn={false}/>
                </Col>
            </Row>

            <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
                Export to CSV
            </Button>
        </>
    );
};